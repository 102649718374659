import { createSlice } from '@reduxjs/toolkit'

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
      value: {
        coordinates: [-73.9857,40.71349], 
        zoom: [12]
    }
  },
  reducers: {
    flyto: (state = {coordinates: [-73.9857,40.71349], zoom: [12]}, action) => {        
      state.value=action.payload.value;
    }
  }
})

export const { flyto } = mapSlice.actions;
export const selectMapState = state => state.map.value;

export default mapSlice.reducer;