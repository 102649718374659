import '../styles/NeighborhoodButton.scss';

function NeighborhoodButton (props) {
    return (
               <div className="nyubasicpromo adaptiveimage image parbase section">
                   <div className="neighborhood component" >
                       <div className="promo-text-component">
                           <a href={`/locations?coordinate1=${props.neighborhood.coordinates[0]}&coordinate2=${props.neighborhood.coordinates[1]}`} className="promo brand_color_1 neighborhoodAnchor">
                               <h6 className="promo-title">{props.neighborhood.hood}</h6>
                               <div className="promo-content">
                                   <p></p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
    )
}
export default NeighborhoodButton;