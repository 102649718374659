import LocationButton from './LocationButton';
import '../styles/LocationRow.scss';


function LocationRow(props) {
  const locationRow=props.row.map((location, index)=> {
    return (<div key={location.id} className={`col cc_33-33-33_c${index}`}>
        <LocationButton location = {location} coordinates={props.coordinates}></LocationButton>
    </div>);
  });
  return (
    <div className="locationRow nyucolumncontrol parbase section">
      {locationRow}   
    </div>
  );
}

export default LocationRow;