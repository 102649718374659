import { useEffect, useState } from "react";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {flyto} from '../mapSlice';

import {TreeLinks, TreeitemLink} from "../treeitem.js";
import * as datehelpers from "../datehelpers.js";

import MealList from "../components/MealList.js";
import HourListItem from "../components/HourListItem";

import "../styles/Menu.scss";

import balancedU from "../styles/img/balanced_u.png";
import sodium from "../styles/img/sodium.png";
import vegan from "../styles/img/vegan.png";
import vegetarian from "../styles/img/vegetarian.png";

function Menu(props) {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const urlDate = searchParams.get('date');
    const urlID = searchParams.get('locationID'); 
    const urlCoordinates = [searchParams.get('coordinate1'), searchParams.get('coordinate2')]; 
    const locationData = useSelector(state => state.locations.value);

    const [loading, setLoading] = useState(true);
    const [mealList, setMealList] = useState([]);
    const [hourHeader, setHourHeader] = useState([]);
    const [hourList, setHourList] = useState([]);
    const [location, setLocation] = useState('');
    const [address, setAddress] = useState('');
    const [date, setDate] = useState(urlDate);
  
  
    async function getMenuData(selectedDate, selectedLocation) { //ajax call to get menu data for an individual location
        //prod:
        const menuResponse = await axios.get('https://afgibjiqy1.execute-api.us-east-1.amazonaws.com/default/nyu-it-dgcom-dining-dev', { params: { date: selectedDate, location: selectedLocation } });
        //dev:
        // const menuResponse = await axios.get('/menuSample.json');
        let weekSchedule = [];
        let menuData = menuResponse.data;
        if(menuData.status === "success"){
            setLoading(false);
            let menu = menuData.menu;
            if(menu !== undefined && menu.periods.length > 0) {
                let mealPeriods = menu.periods;
                setMealList(mealPeriods.map((mealPeriod)=> {
                    return (<div key={mealPeriod.name.replace(/\s+/g, '')}>
                        <MealList mealName={mealPeriod.name} categories={mealPeriod.categories}/>
                    </div>);
                }))
            }
        }
        locationData.forEach((value) => {
            if (value.id === selectedLocation) {
                setLocation(value.name);
                let street = value.address?.street;
                setAddress(<a href={`https://www.google.com/maps/place/${street}+New+York,+NY`}> {street} </a>);
                if (value.schedules) {
                    weekSchedule = datehelpers.getHours(value.schedules, datehelpers.getDateFromNYCDateString(`${selectedDate} 12:00`))
                }
            }
        });

        if (weekSchedule.filter((daySchedule) => datehelpers.isInvalidDate(daySchedule.day)).length) { // Checks if any date is invalid (usually they are all invalid if the user input an invalid date in #mealDate)
            setHourList(<p className="removable"><strong>Please input a valid date.</strong></p>);
        } else if (weekSchedule.length) { // there should always be a DaySchedule for each day of week, unless the location is not found
            const weekStartDay = weekSchedule[0].day;
            const weekEndDay = weekSchedule[weekSchedule.length - 1].day;
            setHourHeader(
                    <p className="removable"><strong>{datehelpers.getNYCDateStringFromDate(weekStartDay)}–{datehelpers.getNYCDateStringFromDate(weekEndDay)}</strong></p>
            );
            setHourList(
                weekSchedule.map((daySchedule) => {
                    const dayOfWeek = datehelpers.getNYCDateComponentsFromDate(daySchedule.day).dayOfWeek;
                    return (<HourListItem key={datehelpers.numToDay(dayOfWeek)} day={datehelpers.numToDay(dayOfWeek)} schedule = {daySchedule.hours.join(", ") || "Closed"}/>);
                })
            )
        } else {
            setHourList(<p className="removable"><strong>There is no hours data available for this location at this time.</strong></p>);
        }  
    }

    useEffect( () => {
        getMenuData(urlDate, urlID);
        dispatch(flyto({type:'flyto', value: {zoom:15, coordinates: [urlCoordinates[1],urlCoordinates[0]]}}));
    }, [locationData]);
  
    useEffect( () => {
        let trees = document.querySelectorAll('[role="tree"]');
        for (var i = 0; i < trees.length; i++) {
            var t = new TreeLinks(trees[i]);
            t.init();
        }
    }, mealList );

    const handleSubmit = (event) => {
        event.preventDefault();
        getMenuData(date, urlID);
    }
    const handleChange = (event) => {
        setDate(event.target.value);
    }
    return (
        <div id="menucontainer">
            <div className="nyupromobutton parbase">
                <div className="component">
                <a href={`/locations?coordinate1=${urlCoordinates[0]}&coordinate2=${urlCoordinates[1]}`} id="backToLocations" className="promo-button-text">
                    Back to Locations
                </a>
                </div>
            </div>
            <p id="message"></p>
            <h3 id="menu-heading">{location}</h3>
            <div className="locationInfo nyucolumncontrol parbase section">
                <div className ="col cc_50-50_c0">
                    <h4>Address:</h4><p id="location">{address}</p>
                    <form id="dateForm" onSubmit={handleSubmit}>
                        <label  for="mealDate">See the menu for this date: </label><br />
                        <input name="mealDate" onChange={handleChange} id="mealDate" placeholder="Date of meal" type="date" min= "2020-01-01" max="2026-01-01" value={date}/>
                        <div className="nyupromobutton parbase"> 
                            <div className="component" id="submitDate">
                                <input type="submit" value="Go" className="promo-button-text"/>
                            </div>
                        </div>
                    </form>
                </div>
                <div className ="col cc_50-50_c1">
                        <div className="component" id="hours">
                            <h4 className="removable">Hours</h4>
                            {hourHeader}
                            <span className="removable" role="list">
                                {hourList}
                            </span>
                        </div>
                </div>
            </div>
            <div className = "component">
                <h4 id='allergenKey'>Allergen Key:</h4>
                        <span role="list">
                        <span className="allergen" role="listitem"><img src={balancedU} alt="Balanced U"/> Balanced U </span>
                        <span className="allergen" role="listitem"><img src={sodium} alt="Sodium"/> Sodium </span>
                        <span className="allergen" role="listitem"><img src={vegan} alt="Vegan"/> Vegan </span>
                        <span className="allergen" role="listitem"><img src={vegetarian} alt="Vegetarian"/> Vegetarian </span>
                    </span>
                </div>
            <div className="parbase nyudivider section">
                <hr className="divider"/>
            </div>
            <p id="error-text"></p>
            {loading && 
            <p id="loading">Loading Menu...</p>
            }
            <h4 id="tree1">Meal Periods</h4>
            {mealList}
                <div className="parbase nyudivider section">
                <hr className="divider"/>
            </div>
        </div>
    );
}

export default Menu;
