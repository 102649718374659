import { useState } from "react";
import '../styles/LocationButton.scss';
import {getCurrentDate} from '../datehelpers.js';

function LocationButton(props) {
    const [date, setDate] = useState(getCurrentDate('-'));
    return (
        <div className="nyubasicpromo adaptiveimage image parbase section">
            <div className="component location">
                <div className="promo-text-component">
                <a href={`/menu?date=${date}&locationID=${props.location.id}&coordinate1=${props.coordinates[0]}&coordinate2=${props.coordinates[1]}`} className="promo locationAnchor">
                        <h6 className="promo-title">{props.location.name}</h6>
                        <div className="promo-content">
                            <p><span className="address">{props.location.address.street}, {props.location.address.city}, {props.location.address.state} {props.location.address.zip_code}</span></p>
                        </div>
                        <div className={props.location.status.label.toUpperCase()}>
                            <span className={`${props.location.status.label.toUpperCase()}Text`}>{props.location.status.label.toUpperCase()}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default LocationButton;
