import { useEffect, useState } from "react";

function MenuItem (props) {
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        if(props.item.filters !==undefined){
            let filters = props.item.filters;
                let filterNames = ['balanced u','vegetarian','vegan','sodium'];
    
                //get filters, make sure it's in our icon list, and add to results
                setFilterList(filters.map((filter) => {
                    if (filterNames.indexOf(filter.name.toLowerCase().replace('*', ''))>-1){
                        return(
                            <img key={filter.id} src={`../styles/img/${filter.name.toLowerCase().replace(/\*| /g, '')}.png`} alt={filter.name} title={filter.name}/>
                        ); 
                    }
                }));
        }
    }, [])
    
    if(props.item.filters !==undefined){
        return (
            <li role="treeitem" tabIndex="-1">
                <span className="item"> 
                    <span className="name">{props.item.name}</span>
                    <span className="calories">{props.item.calories}cal</span><br/>
                    {filterList}
                </span>
            </li>
        )
    }
    return (
            <li role="treeitem" tabIndex="-1">
                <span className="item"> 
                    <span className="name">{props.item.name}</span>
                    <span className="calories">{props.item.calories}</span><br/>
                </span>
            </li>
    )
}

export default MenuItem;
