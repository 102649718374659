import MenuCategory from "./MenuCategory.js"

function MealList (props) {
    const categoryList = props.categories.map((category) => {
        return (
            <MenuCategory key={category.name} categoryName={category.name} items={category.items}/>
        )
    })

    return (
        <div>
            
            <ul className="mealPeriods" id="treeContainer"role="tree" aria-labelledby="tree1">
                <li className="removable mealPeriod" id={`${props.mealName.replace(/\s+/g, '')}Container`} role="treeitem" aria-expanded="false" tabIndex="0">
                    <span className="">{props.mealName}</span>
                    <ul role="group" className="result">
                        {categoryList}
                    </ul>
                </li>
            </ul>
        </div>)

}

export default MealList;