import '../styles/Footer.scss';

function Footer() {
    return (
        <footer id="footer" className="footer-bottom-wrapper">
            <div className="footer-content">
                <div className="footer-bottom-menu">
                    <div className="highlighted-item">
                        <a href="https://www.nyu.edu" className="ui-link">New York University</a>
                    </div>
                    <ul>
                        <li>
                            <a href="https://www.nyu.edu/students.html" target="_self" className="footer-navigation-bottom-menu-link ui-link">Students</a>
                        </li>
                        <li>
                            <a href="https://www.nyu.edu/faculty.html" target="_self" className="footer-navigation-bottom-menu-link ui-link">Faculty</a>
                        </li>
                        <li>
                            <a href="https://www.nyu.edu/alumni.html" target="_self" className="footer-navigation-bottom-menu-link ui-link">Alumni</a>
                        </li>
                        <li>
                            <a href="https://www.nyu.edu/employees.html" target="_self" className="footer-navigation-bottom-menu-link ui-link">Employees</a>
                        </li>
                        <li>
                            <a href="https://www.nyu.edu/community.html" target="_self" className="footer-navigation-bottom-menu-link ui-link">Community</a>
                        </li>
                    </ul>
                </div>
                <div className="copyright">Unless otherwise noted, all content copyright New York University. All rights reserved.
                </div>
            </div>
        </footer>
    )
}

export default Footer;