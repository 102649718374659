import NeighborhoodButton from './NeighborhoodButton';
import '../styles/NeighborhoodList.scss';

function NeighborhoodList(props) {
  const neighborHoodList=props.neighborhoods.map((neighborhood)=> {
    return (<div key={neighborhood.id} className={`col cc_33-33-33_c${neighborhood.id}`}>
        <NeighborhoodButton neighborhood={neighborhood}/>
    </div>);
});
  return (
       <div className="nyucolumncontrol parbase section">
         {neighborHoodList}
       </div>
  );
}

export default NeighborhoodList;
