import { createSlice } from '@reduxjs/toolkit';

export const locationSlice = createSlice({
  name: 'locations',
  initialState: {
      value: []
  },
  reducers: {
    addLocations: (state,action) => {
        state.value=action.payload;
      }
  }
})

export const { addLocations } = locationSlice.actions;
export default locationSlice.reducer;