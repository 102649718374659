import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import {addLocations} from './locationSlice';
import Menu from './views/Menu';
import Locations from './views/Locations';
import Neighborhoods from './views/Neighborhoods';
import Footer from './components/Footer';
import Map from './components/Map';
import './styles/App.scss';
import './styles/fonts.scss';
import 'font-awesome/css/font-awesome.min.css';


function App() {
  const neighborhoods = [{'id':0, 'hood':"Washington Square", 'coordinates':[40.7300846,-73.9957096]},{'id':1, 'hood':"Union Square - 14th Street", 'coordinates':[40.7336794,-73.9893209]},{'id':2, 'hood':"Brooklyn - Jay St. Metrotech", 'coordinates':[40.6942793,-73.98646868]}];
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      //prod:
      axios.get('locations.json').then(response =>  dispatch(addLocations(response.data)));
      //axios.get('/locations.json').then(response =>  dispatch(addLocations(response.data)));
    } catch (err) {
      // If something went wrong, handle it here
    }
  }, []);
  

  return (
    <div class="dining">
      <Router>
          <Routes>
            <Route path="/menu" element={<Menu/>}/>
            <Route path="/locations" element={<Locations/>}/>
            <Route path="/" element={<Neighborhoods neighborhoods={neighborhoods}/>}/>
            <Route path="/index.html" element={<Neighborhoods neighborhoods={neighborhoods}/>}/>
          </Routes>
      </Router>
      <Map />
      <Footer />
    </div>
      
  );
}

export default App;