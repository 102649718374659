import NeighborhoodList from '../components/NeighborhoodList.js'
import Map from '../components/Map.js'
import '../styles/Neighborhoods.scss';

function Neighborhoods (props) {
    return (
        <div id="neighborhoods">
              <h2>Where would you like to eat?</h2>
              <h3>Neighborhoods</h3>
              <NeighborhoodList neighborhoods={props.neighborhoods}/>
    </div>
    )
    
}

export default Neighborhoods;