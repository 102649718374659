import { useRef, useEffect, useState } from "react";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import {flyto} from '../mapSlice';

import fork from '../styles/img/restaurant-fork-shorter-01.svg';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/Map.scss';
import {getCurrentDate} from '../datehelpers.js';

mapboxgl.accessToken = 'pk.eyJ1IjoibnYyNCIsImEiOiJjazVzbmlubHEwcThjM2tvNjk4enZ4dTJ4In0.acR7phhZxxseHwEWQJpREg';
function Map (props) {
    const dispatch = useDispatch();
    const mapContainer = useRef(null);
    const map = useRef(null);

    const zoom = useSelector(state => state.map.value.zoom);
    const coordinates = useSelector(state => state.map.value.coordinates);
    const locationData = useSelector(state => state.locations.value);

    const [currentMarkers, setCurrentMarkers] = useState([]);
    const [mapFeatures, setMapFeatures] = useState([]);
    const [date, setDate] = useState(getCurrentDate('-'));
    
    function renderFeatures(mapFeatures) {
        //adjust map bounding box
        let geoBounds = map.current.getBounds();

        // remove existing markers
        if (currentMarkers.length>0) {
            for (var i = currentMarkers.length - 1; i >= 0; i--) {
                currentMarkers[i].remove();
                currentMarkers.splice(i,1);
            }
        }
        mapFeatures.forEach(function(marker) {
            // make a marker for each feature and add to the map
            if(geoBounds.contains(marker.geometry.coordinates)){
                // create an HTML element for each feature if it is in the current geographic bounds
                let el = document.createElement('div');
                let popupDiv = document.createElement('div');
                popupDiv.setAttribute('role', 'modal');
                popupDiv.innerHTML = `<p class="mapLocation"><strong><a class="popupHeading" tabindex="0" href="/menu?date=${date}&locationID=${marker.properties.ID}&coordinate1=${marker.geometry.coordinates[1]}&coordinate2=${marker.geometry.coordinates[0]}" data-id="${marker.properties.ID}">${marker.properties.Name}</a></strong><br />${marker.properties.Description}</p>`;

                const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: true, closeOnMove: true, focusAfterOpen: true }).setDOMContent(popupDiv);
                el.className = 'marker';
                el.setAttribute('aria-haspopup', 'dialog');
                el.setAttribute('role', 'button');
                el.setAttribute('aria-label', marker.properties.Name);
                el.setAttribute('title', marker.properties.Name);
                el.setAttribute('tabindex', 0);
                el.style.backgroundImage =`url(${fork})`;
                el.style.backgroundPosition = 'top';
                el.style.width = '30px';
                el.style.height = '30px';

                el.className = 'mapboxgl-accessibility-marker';
                let newMarker = new mapboxgl.Marker(el)
                    .setLngLat(marker.geometry.coordinates)
                    .setPopup(popup) // add popups

                    //.setOffset(mapOffset)
                    .addTo(map.current);
                currentMarkers.push(newMarker);
            }
        });
    }

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/nv24/ck6ayd4yz010s1iqtkl4f1t0l',
        center: [-73.9857,40.71349],
        zoom: zoom
        });
    }, []);

    // useEffect(() => {
    //     console.log(coordinates);
    //     if (!map.current) return; // wait for map to initialize
    //     map.current.on('move', () => {
    //         dispatch(flyto({type:'flyto', value: {zoom:map.current.getZoom().toFixed(2), coordinates: [map.current.getCenter().lng,map.current.getCenter().lat]}}));
    //     });
    // });

    useEffect(() => {
        const destination = coordinates ? coordinates : [-73.9857,40.71349];
        let newBearing = -10;
        if(destination[1] === -73.98646868) {
            newBearing=75;
        }
        else if (destination[1] === -73.9893209) {
            newBearing=135;
        }
        map.current.flyTo({ center: [ parseFloat(destination[0]), parseFloat(destination[1])], zoom: zoom || 12, bearing: newBearing, container: 'map', antialias: true});
    }, coordinates, zoom);

    useEffect(() => {
        let features = [];
        function getFeatures() {
            locationData.forEach(element => {
                let feature = {};
                feature.Type="Feature";

                // TODO: Switch after backend change
                if (element.status) {
                    feature.properties = {ID: element.id,Name: element.name, Address: element.address?.street,Description: element.status.message};
                } else {
                    feature.properties = {ID: element.id,Name: element.name, Address: element.address?.street,Description: element.description};
                }

                if (Array.isArray(element.address?.coordinates)) {
                    feature.geometry = {coordinates: [element.address.coordinates[0]+(Math.random() * 0.00004 - 0.00002), element.address.coordinates[1]+(Math.random() * 0.00004 - 0.00002)], type:'Point'};
                } else {
                    feature.geometry = {coordinates: [element.address?.lon, element.address?.lat], type:'Point'};
                }
                features.push(feature);
               });
               renderFeatures(features);
        }
        getFeatures();
        setMapFeatures(features);
    }, [locationData]);

    useEffect(() => {
        map.current.on('zoom', () => {
            renderFeatures(mapFeatures);
        });
    })

    return (
        <div id="map">
            <h2>Map of NYU Dining Locations</h2>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}

export default Map;